import "./App.css";
import HomePage from "./components/HomePage.jsx";


const App = () => {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
};

export default App;